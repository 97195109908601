<template>
    <div>
        <Layout>
           <div class="row mt-3">
            <div class="col-lg-12">
                <div class="" style="
                    background-color: #FFF2D9;
                    border-radius: 5px;
                    padding: 10px 15px 10px 15px;
                ">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="row">
                                <div class="col-sm-2 col-lg-2">
                                    <img src="../../../../assets/images/pin-point.png" alt="" style="
                                        width: 46px;
                                        margin: 0px 0px 0px 20px;
                                    ">
                                </div>
                                <div class="col-sm-10 col-lg-10">
                                    <div v-if="sendNo === null ">
                                        <b>Find the Item you want to tracking</b><br>
                                        <p>Detect tracking status</p>
                                    </div>
                                    <div v-else>
                                        <p style="margin: 5px 0px 0px 0px;">No: </p>
                                        <b  style="font-size: 16px; color: black; margin: 0px 0px 10px 0px">{{sendNo.name}}</b>
                                        <!-- <b v-else-if="vesselOrFlight === 'Flight'" style="font-size: 16px; color: black; margin: 0px 0px 10px 0px">{{selectnovessel.flight_no}}</b> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="row">
                                
                                <div class="col-lg-12 col-sm-12 text-right">
                                    <button class="btn mt-1" id="track-vessel" style="
                                        background-color: #F9A707;
                                        color: white;
                                    " @click.prevent="$bvModal.show('modal-input-vessel')">
                                        <i class="fa fa-map-marker"></i> Tracking your shipment
                                    </button>
                                    <!-- modal input vessel -->
                                    <b-modal size="sm" id="modal-input-vessel" hide-footer hide-header>
                                        <div class="container text-center">
                                            <img class="text-center" src="../../../../assets/images/tracking.png" alt="" style="width: 56px; height: 56px;">
                                            <p class="mt-2 mb-3 text-center">
                                                <span class="font-weight-bold" style="font-size: 16px;">Input No</span><br>
                                                <span>Tracking Your Shipment</span>
                                            </p>
                                            <div class="text-left">
                                                <label for="">Type No</label>
                                            </div>
                                            <div class="form-group mb-3">
                                                <VueSelect v-model="vesselOrFlight" class="vue-select1" :options="listType" :clearable="false">
                                                </VueSelect>
                                            </div>

                                            <div v-if="vesselOrFlight === 'Vessel'">
                                                <div class="text-left">
                                                    <label>No Vessel</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnovessel" class="vue-select1" label="name" :reduce="(option) => option" :options="listVesselAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'Flight'">
                                                <div class="text-left">
                                                    <label>No Flight</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoflight" class="vue-select1" label="name" :reduce="(option) => option" :options="listAirlinesAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'MBL. Number'">
                                                <div class="text-left">
                                                    <label>MBL. Number</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoMbl" class="vue-select1" label="name" :reduce="(option) => option" :options="listMblAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'HBL. Number'">
                                                <div class="text-left">
                                                    <label>HBL. Number</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoHbl" class="vue-select1" label="name" :reduce="(option) => option" :options="listHblAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'MAWB'">
                                                <div class="text-left">
                                                    <label>MAWB</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoMawb" class="vue-select1" label="name" :reduce="(option) => option" :options="listMawbAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'HAWB'">
                                                <div class="text-left">
                                                    <label>HAWB</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoHawb" class="vue-select1" label="name" :reduce="(option) => option" :options="listHawbAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div v-else-if="vesselOrFlight === 'BL'">
                                                <div class="text-left">
                                                    <label>BL</label>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <VueSelect v-model="selectnoBl" class="vue-select1" label="name" :reduce="(option) => option" :options="listBlAll" :clearable="false">
                                                    </VueSelect>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div class="col-6 text-left">
                                                    <button class="btn" style="
                                                        background-color: #E0E0E0;
                                                        width: 90px;
                                                        height: 34px;
                                                    " @click.prevent="$bvModal.hide('modal-input-vessel')">Cancel</button>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <button class="btn" style="
                                                        background-color: #51C1AD;
                                                        width: 90px;
                                                        height: 34px;
                                                    " @click.prevent="onSubmitVessel">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </b-modal>
                                    <!-- end modal input vessel -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div> 

           <div class="row">
            <div class="col-12">
                <div v-if="!isTrackingList">
                    <div style="
                        background-color: #F9FAFA;
                        height: 500px;
                        margin: 26px 0px 26px 0px;
                    ">
                        <div class="text-center" style="
                            padding-top: 200px;
                            padding-bottom: 200px;
                        ">
                            <img src="../../../../assets/images/no-data.png" alt="" style="
                                width: 56px; height: 56px;">
                            <br>
                            No data tracking yet
                        </div>
                    </div>
                </div>
                <div v-else>
                    <TrackingShipmentList :novessel=sendNo :vesselOrFlight=vesselOrFlight></TrackingShipmentList>
                </div>
            </div>
           </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@layouts/main'
import VueSelect from 'vue-select'
import TrackingShipmentList from '@/src/components/TrackingShipment/TrackingShipmentList.vue'
import { mapActions, mapState } from 'vuex'

export default {
    components: {
    Layout,
    VueSelect,
    TrackingShipmentList
},
    data() {
        return {
            listType: ['Vessel','Flight', 'HBL. Number' ,'MBL. Number', 'MAWB', 'HAWB', 'BL'],
            result1: '',
            isTrackingList: false,
            selectnovessel: null,
            selectnoflight: null,
            selectnoHawb: null,
            selectnoMawb: null,
            selectnoHbl: null,
            selectnoMbl: null,
            selectnoBl: null,
            sendNo: null,
            sendNoFlight: null,
            listJobfileVessel: '',
            vesselOrFlight: '',
            headers: {
				'Content-Type': 'application/json',
			},
        }
    },

    computed: {
  		...mapState('vessel', ['listVesselAll']),
  		...mapState('airlines', ['listAirlinesAll']),
  		...mapState('jobfile', ['listHblAll', 'listMblAll' , 'listMawbAll', 'listHawbAll', 'listBlAll' ])
    },

    mounted(){
        this.getListVesselAll()
        this.getListAirlinesAll()
        this.getListHawbAll()
        this.getListMawbAll()
        this.getListMblAll()
        this.getListHblAll()
        this.getListBlAll()
    },

    methods: {
        ...mapActions('vessel', ['getListVesselAll']),
        ...mapActions('airlines', ['getListAirlinesAll']),
        ...mapActions('jobfile', ['getListJobfileByVessel', 'getListHawbAll', 'getListMawbAll', 'getListMblAll', 'getListHblAll', 'getListBlAll']),

        onSubmitVessel(){
            if(this.vesselOrFlight === 'Vessel'){
                this.sendNo = this.selectnovessel
            } else if(this.vesselOrFlight === 'Flight'){
                this.sendNo = this.selectnoflight
            } else if(this.vesselOrFlight === 'HBL. Number'){
                this.sendNo = this.selectnoHbl
            } else if(this.vesselOrFlight === 'MBL. Number'){
                this.sendNo = this.selectnoMbl
            } else if(this.vesselOrFlight === 'MAWB'){
                this.sendNo = this.selectnoMawb
            } else if(this.vesselOrFlight === 'HAWB'){
                this.sendNo = this.selectnoHawb
            } else if(this.vesselOrFlight === 'BL'){
                this.sendNo = this.selectnoBl
            }
            // this.getListJobfileByVessel({
			// 	payload: this.selectnovessel,
			// 	headers: this.headers
			// })
            //     .then((res) => {
            //         // listJobfileVessel = res
            //         // console.log(this.sendNo,'ress');
            //     })
            this.isTrackingList = true
            this.$bvModal.hide('modal-input-vessel')
        }
    },
}
</script>

<style>
    #track-vessel:hover{
        color: white;
    }
</style>