<template>
    <div>
        <div style="
            margin: 26px 0px 26px 0px;
        ">
            <div class="row">
                <div class="col-10 mx-0 my-0 p-0">
                    <div class="row">
                        <div class="col-lg-1 col-sm-2">
                            <input type="checkbox" name="" id="checkedAll" @click="checkedAll" class="ml-3 mt-1">
                        </div>
                        <div class="col-lg-2 col-sm-2 pt-1">
                            <span class="" style="font-size: 16px; font-weight: 900;">List Job</span>
                        </div>
                        <div class="col-lg-2 col-sm-4">
                            <button class="btn" style="
                                background-color: #51C1AD; 
                                color: white;
                                width: 125px; 
                                height: 34px;
                                font-size: 12px;
                                " @click.prevent="modalUpdateStatusMulti">
                                <i class="fa fa-edit"></i> Update Status
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 text-right">
                    <div class="inputWithIcon">
                        <input v-model="filterText" type="text" class="form-control form-control-sm mb-2" placeholder="Search . . ." @keyup="doFilter">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true" :http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData">
                            <div slot="checkbox" slot-scope="props" class="table-cell row" >
                                <div>
                                    <input type="checkbox" style="position: relative;top:10px" class="form-control form-control-sm checkboxStyle" :id="`${props.rowData.id_jobfile}`" ref="theCheckbox" :disabled="props.rowData.status === 'Finished'" true-value="1" false-value="0" />
                                </div>
                            </div>
                            <div slot="jobfile_no" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        No Job :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.no_job }}
                                    </div>
                                </div>
                            </div>
                            <div slot="customer" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        Customer :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.customer  }}
                                    </div>
                                </div>
                            </div>
                            <div slot="bl_number" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        <span v-if="vesselOrFlight === 'Vessel'">BL No :</span>
                                        <span v-if="vesselOrFlight === 'BL'">BL No :</span>
                                        <span v-if="vesselOrFlight === 'Flight'">Flight No :</span>
                                        <span v-if="vesselOrFlight === 'HBL. Number'">HBL. Number :</span>
                                        <span v-if="vesselOrFlight === 'MBL. Number'">MBL. Number :</span>
                                        <span v-if="vesselOrFlight === 'HAWB'">HAWB No :</span>
                                        <span v-if="vesselOrFlight === 'MAWB'">MAWB No :</span>
                                    </div>
                                    <div class="font-data">
                                        {{ props.rowData.bl_number }}
                                        
                                    </div>
                                </div>
                            </div>
                            <div slot="eta" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        ETA :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.eta ? dateFormating(props.rowData.eta) : '' }}
                                    </div>
                                </div>
                            </div>
                            <div slot="etd" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        ETD :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.etd ? dateFormating(props.rowData.etd) : '' }}
                                    </div>
                                </div>
                            </div>
                            <div slot="marketing" slot-scope="props" class="table-cell row" >
                                <div class="">
                                    <div class="mb-1">
                                        Sales Marketing :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.marketing }}
                                    </div>
                                </div>
                            </div>
                            <div slot="created_by" slot-scope="props" class="table-cell cell-created-by row" >
                                <div class="">
                                    <div class="mb-1">
                                        Created By :
                                    </div>
                                    <div class="font-data">
                                        <!-- {{ props.rowData }} -->
                                        {{ props.rowData.created_by }}
                                    </div>
                                </div>
                            </div>
                            <div slot="status" slot-scope="props" class=" row" >
                                <div class="" v-if="props.rowData.status !== 'Finished' && props.rowData.status !== ''" :style="{ backgroundColor: props.rowData.status_color, borderRadius: 5 + 'px', width: 100 + '%', textAlign: 'center'  }">
                                    <div class="statusTab px-4 py-2" style="color: white;">
                                            {{ props.rowData.status }}
                                    </div>
                                </div>
                                <div class="" v-if="props.rowData.status === 'Finished'" :style="{ backgroundColor: props.rowData.status_color, borderRadius: 5 + 'px', width: 100 + '%'  }">
                                    <div class="statusTab px-2 py-2 row" style="color: white">
                                        <!-- {{ props.rowData }} -->
                                        <div class="col-lg-3">
                                            <img src="../../assets/images/icon-sp-4.png" style="width: 20px" alt="" srcset="">
                                        </div>
                                        <div class="col-lg-8" style="padding-top: 2px">
                                            {{ props.rowData.status }}
                                        </div>
                                    </div>
                                </div>
                                <div class="" v-if="props.rowData.status === ''" :style="{ backgroundColor: '#276cb8', borderRadius: 5 + 'px', width: 100 + '%', textAlign: 'center' }">
                                    <div class="statusTab px-3 py-2" style="color: white">
                                        <!-- {{ props.rowData }} -->
                                            Proses Penjadwalan
                                    </div>
                                </div>
                            </div>
                            <div slot="actions" slot-scope="props" class="table-cell row" >
                                <div>
                                    <b-dropdown id="dropdown" right variant="link">
                                        <template v-slot:button-content>
                                            <button style="
                                                background-color: #CFFDF5;
                                                width: 35px;
                                                height: 35px;
                                                border: none;
                                                ">
                                                <i class="fa fa-caret-down" style="
                                                    color: #51C1AD;
                                                "></i>
                                            </button>
                                        </template>
                                        <b-dropdown-item @click.prevent="onViewTimeline(props.rowData.id_jobfile)"><i class="fa fa-list text-primary"></i> View Timeline</b-dropdown-item>
                                        <b-dropdown-item v-if="props.rowData.status !== 'Finished'" @click.prevent="modalUpdateStatus([props.rowData.id_jobfile])"><i class="fa fa-edit text-primary"></i> Update Status</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </vuetable>
                    </div>
                    <!-- pagination -->
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row no-gutters">
                                <div class="col-lg-3">
                                    <div class="form-group float-lg-left">
                                        <select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
                                            <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                                                {{ opt }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <vuetable-pagination-info ref="paginationInfo" class="
                                    justify-content-center justify-content-lg-start
                                    text-center text-lg-left
                        ">
                                    </vuetable-pagination-info>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-7">
                            <vuetable-pagination ref="pagination" :css="css.pagination" class="
                                pagination pagination-rounded
                                justify-content-center justify-content-lg-end
                            " 
                            
                            @vuetable-pagination:change-page="onChangePage">
                            </vuetable-pagination>
                        </div>
                    </div>
                    <!-- end pagination -->

                    <!-- modal update status -->
                    <b-modal size="sm" id="modal-update-status" hide-header hide-footer>
                        <div class="text-center">
                            <img src="../../assets/images/box-location.png" alt="" style="
                                width: 56px;
                                height: 56px;
                            ">
                            <br>
                            <b>Update Status</b>
                        </div>
                        <div class="container mt-2">
                            <div class="form-group">
                                <label for="">Timeline Tracking <span class="text-danger">*</span></label>
                                <VueSelect v-model="dataStatus.timeline" class="vue-select1" label="title" :reduce="(option) => option.id" :options="listTimeline" :clearable="false">
                                </VueSelect>
                            </div>
                            <div class="form-group">
                                <label for="">Port</label>
                                <VueSelect v-model="dataStatus.port" class="vue-select1" label="name_port" :reduce="(option) => option.id" :options="listPort" :clearable="false">
                                </VueSelect>
                            </div>
                            <div class="form-group">
                                <label for="">Description</label>
                                <textarea v-model="dataStatus.description" name="" id="" cols="10" rows="5" class="form-control"></textarea>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-6 text-left">
                                    <button class="btn" style="
                                        background-color: #E0E0E0;
                                        width: 90px;
                                        height: 34px;
                                    " @click.prevent="$bvModal.hide('modal-update-status')">Cancel</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn" style="
                                        background-color: #51C1AD;
                                        width: 90px;
                                        height: 34px;
                                    " @click.prevent="$bvModal.show('modal-confirm-update')">Update</button>
                                </div>
                            </div>
                        </div>
                    </b-modal>
                    <!-- end modal update status -->

                    <!-- modal confirm update -->
                    <b-modal id="modal-confirm-update" hide-header hide-footer>
                        <div class="text-center mt-3">
                            Do you confirm to update it ?
                        </div>
                        <div class="text-center mt-4 mb-2">
                            <button class="btn" style="
                                background-color: #e0e0e0;
                                width: 90px;
                                height: 34px;
                                margin: 0px 5px 0px 0px;
                            " @click.prevent="$bvModal.hide('modal-confirm-update')">No</button>
                            <button class="btn" style="
                                background-color: #51C1AD;
                                width: 90px;
                                height: 34px;
                                margin: 0px 0px 0px 5px;"
                                @click.prevent="updateStatus"
                >Yes</button>
                        </div>
                        

                    </b-modal>
                    <!-- end modal confirm update -->
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
import VueSelect from 'vue-select'
import Swal from 'sweetalert2'
// var url = '/shipment-access'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
//   if (
//     JSON.parse(localStorage.getItem('userPermissions'))[i] ===
//     'CRM_ADMIN_ENABLE'
//   ) {
//     url = '-'
//   }
}

export default {
    mixins: [VuetableFieldCheckboxMixin],
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        VueSelect,
    },
    props: ['novessel', 'vesselOrFlight'],
    data() {
        return {
            lengthMenu: [5, 10, 25, 50, 100],
  			perPage: 5,
            currentPage: 1,
            apiUrl: this.$store.state.rootApi + '/jobfile/get-list-jobfile-by-tracking?id=' + this.novessel.id + '&name=' + this.novessel.name + '&type=' + this.vesselOrFlight,
            moreParams: {},
  			filterText: '',
            dataStatus: {
                timeline: '',
                port: '',
                description: '',
                id_jobfile: [],
            },
            
  			fields: [{
  					name: 'checkbox',
  					title: '',
  					//   sortField: 'no',
  				},{
  					name: 'jobfile_no',
  					title: '',
  					//   sortField: 'no',
  				},
				{
  					name: 'customer',
  					title: '',
  					// sortField: 'po_number',
					// titleClass: 'ml-5',
  					// dataClass: 'text-center',
  				},
				{
  					name: 'bl_number',
  					title: '',
  					// sortField: 'po_number',
					// titleClass: 'ml-5',
  					// dataClass: 'text-center',
  				},
  				{
  					name: 'eta',
  					title: '',
  				},
  				{
  					name: 'etd',
  					title: '',
  				},
  				{
  					name: 'marketing',
  					title: '',
  				},
  				{
  					name: 'created_by',
  					title: '',
  					// dataClass: 'text-center ',
  				},
  				{
  					name: 'status',
  					title: '',
  					// dataClass: 'text-center ',
  				},
  				{
  					name: 'actions',
  					title: '',
  					// dataClass: 'text-center ',
  				},
  			],
              css: {
  				pagination: {
  					activeClass: 'btn-primary text-white',
  					pageClass: 'btn btn-sm ',
  					linkClass: 'btn btn-sm',
  					icons: {
  						prev: 'fas fa-arrow-left',
  						next: 'fas fa-arrow-right',
  					},
  				},
  			},
            headers: {
				'Content-Type': 'application/json',
			},
            httpOptions: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
  			},
            dateFormating(date) {
                return date ? moment(date).format('DD/MMM/YYYY') : ''
  			},
            pageIndex: 1,
  			// page size
  			pageSize: 10,
  			// selected row keys collection
            // data: [
            //     { no_job: '', customer: '',no_bl: '', eta: '', etd: '', sales: '', created_by: '', status: 'Delivered' },
            //     { no_job: '', customer: '',no_bl: '', eta: '', etd: '', sales: '', created_by: '', status: 'Delivered' },
            //     { no_job: '', customer: '',no_bl: '', eta: '', etd: '', sales: '', created_by: '', status: 'Dispatched' },
            //     { no_job: '', customer: '',no_bl: '', eta: '', etd: '', sales: '', created_by: '', status: 'Finished' },
            //     { no_job: '', customer: '',no_bl: '', eta: '', etd: '', sales: '', created_by: '', status: 'Packaging' },
            // ]
        }
    },
    computed: {
  		...mapState('jobfile', ['listJobfileByVessel']),
  		...mapState('statusShipment', ['listTimeline']),
  		...mapState('port', ['listPort']),
          
    },
    watch: { 
        novessel: function(newVal, oldVal) { // watch it
                this.apiUrl = this.$store.state.rootApi + '/jobfile/get-list-jobfile-by-tracking?id=' + this.novessel.id + '&name=' + this.novessel.name + '&type=' + this.vesselOrFlight
                    this.$nextTick(() => 
                        this.$refs.vuetable.refresh()
                    )
        }
    },

    mounted() {
        this.getListTimeline()
        this.getListPort()

    },

    methods: {
        ...mapActions('statusShipment', ['getListTimeline', 'saveTimeline']),
        ...mapActions('port', ['getListPort']),

        onViewTimeline(idSea){
            this.$router.push(`/view-timeline/${idSea}`)
        },
        modalUpdateStatus(id){
            // console.log(id,'idjob')
            this.dataStatus.id_jobfile = id
            this.$bvModal.show('modal-update-status')
        },
        modalUpdateStatusMulti(){
            this.dataStatus.id_jobfile = []
            let checkbox = document.querySelectorAll('.checkboxStyle')
            checkbox.forEach(element => {
                if(element.checked === true){
                    this.dataStatus.id_jobfile.push(element.id)
                }
            });
            // console.log(this.dataStatus,'datastauts')
            this.$bvModal.show('modal-update-status')
        },

        checkedAll(){
            let checkbox = document.querySelectorAll('.checkboxStyle')
            let checkboxAll = document.getElementById('checkedAll')
            // console.log(checkboxAll.checked,'checked')
            if(checkboxAll.checked === true){
                checkbox.forEach(element => {
                    if(element.disabled === false){
                        element.checked = true
                    }
                });
            } else {
                checkbox.forEach(element => {
                    element.checked = false
                });
            }
        },

        updateStatus(){
            this.saveTimeline({
                payload: this.dataStatus,
				headers: this.headers
            }).then((res) => {
                this.sweetAlerts(res, ['modal-confirm-update', 'modal-update-status'])
            })
        },

        sweetAlerts(res, modal){
			if (res.status) {
				Swal.fire({
				title: "Success!",
				text: res.message,
				icon: 'success',
				confirmButtonColor: '#51C1AD',
				confirmButtonText: 'Ok'
				}).then((res) => {
						this.$nextTick(() => {
                            modal.forEach(element => {
                                this.$bvModal.hide(element)
                            });
							this.$nextTick(() => 
							    this.$refs.vuetable.refresh()
							)
							this.$nextTick(() => 
							    this.$refs.tableEdit.refresh()
							)
						})
				})
			} else {
				Swal.fire({
					title: "Failed!",
					text: res.message,
					icon: 'warning',
					confirmButtonColor: 'rgb(221, 107, 85)',
					confirmButtonText: 'Ok'
				})
			}
		},

        doFilter() {
  			this.onFilterSet(this.filterText)
  		},
        
        onChangeTableLength() {
  			// this.$refs.vuetable.reload()
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		onChangeTableLengthItem() {
  			// this.$refs.vuetable.reload()
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		/**
  		 * Pagination page change
  		 */
  		onChangePage(page) {
            // this.currentPage = page;
            this.$refs.vuetable.changePage(page)
  		},
  		onChangePageItem(page) {
  			this.$refs.vuetableItem.changePage(page)
  		},
  		/**
  		 * Pagination info and pagination show
  		 */
  		onPaginationData(paginationData) {
  			this.$refs.pagination.setPaginationData(paginationData)
  			this.$refs.paginationInfo.setPaginationData(paginationData)
  		},
  		onPaginationDataItem(paginationData) {
			console.log(this.$refs,'refsspagg');
  			this.$refs.paginationItem.setPaginationData(paginationData)
  			// this.$refs.paginationInfo.setPaginationData(paginationData)
			// this.$nextTick(() => this.$refs.vuetable.refresh())
  		},

		
  		/**
  		 * [onFilterSet description]
  		 *
  		 * @param   {[type]}  filterText  [filterText description]
  		 *
  		 * @return  {[type]}              [return description]
  		 */
  		onFilterSet(filterText) {
  			this.moreParams = {
  				search: filterText,
  			}
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		/**
  		 * Show Table Loading
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadingTable() {
  			this.isLoadingTable = true
  		},

  		/**
  		 * Hide Loading if Success
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadTableSuccess() {
  			this.isLoadingTable = false
  		},
  		/**
  		 * Hide Loading if Failed
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadTableFailed(error) {
  			console.log(error)

  			this.isLoadingTable = false
  			this.$swal({
  				title: 'Terjadi Kesalahan',
  				type: 'error',
  				timer: '2000',
  				toast: true,
  				position: 'bottom-end',
  			})
  			// }
  		},
    },
}
</script>

<style>
    input[type="checkbox"]{
        /* appearance: none;
        -webkit-appearance: none; */
        height: 20px;
        width: 20px;
        border-radius: 2px;
        cursor: pointer;
        /* display: flex;
        align-items: center;
        justify-content: center; */
    }
    input[type="checkbox"]:checked{
        background-color: #F9A707;
    }


    .inputWithIcon input[type=text]{
        padding-left: 30px;
    }
    .inputWithIcon{
        position: relative;
    }
    .inputWithIcon i{
        position: absolute;
        left: 0;
        top: 4px;
        padding: 9px 8px;
        color: #aaa;
        transition: .3s;
    }
    .inputWithIcon input[type=text]:focus + i{
        color: #37AB96;
    }

    .font-data{
        font-weight: 700;
        color: #000000;
    }

    .table-cell{
        padding: 0px 10px;
    }

    /* .cell-created-by{
        width: 100%;
    } */
    .vuetable th {
        display: none;
    }
    
    .table-responsive{
        margin-top: 20px;
    }
</style>